import React, { useEffect, useState } from 'react';
import './DisplayJob.tsx.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Descriptions } from 'antd';
import { WorkDescriptions } from './DisplayJob.styles';
import { AttachmentFile, Inventering, SharePointItem, getAttachments } from '../Functions/ApiRequests';
import { useUserContext } from '../contexts/UserContext';
import { ReactImageGalleryItem } from 'react-image-gallery';

interface DisplayJobProps {
  currentItem: any;
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | Inventering | null>>;
}

const DisplayJob: React.FC<DisplayJobProps> = ({ currentItem }) => {

  return (
    <>
      <WorkDescriptions column={1} bordered>
        {currentItem?.ID && <Descriptions.Item label="ID">{currentItem.ID}</Descriptions.Item>}
        {currentItem?.Title && <Descriptions.Item label="Rubrik">{currentItem.Title}</Descriptions.Item>}
        {currentItem?.Kund && <Descriptions.Item label="Rubrik">{currentItem.Kund}</Descriptions.Item>}
        {currentItem?.Adress && <Descriptions.Item label="Adress">{currentItem.Adress}</Descriptions.Item>}
        {currentItem?.Avvikelsetyp && (
          <Descriptions.Item label="Avvikelsetyp">{currentItem.Avvikelsetyp}</Descriptions.Item>
        )}
        {currentItem?.Kostnadsst_x00e4_lle && <Descriptions.Item label="Kostnadsställe">{currentItem.Kostnadsst_x00e4_lle}</Descriptions.Item>}
        {currentItem?.Fastighet && <Descriptions.Item label="Fastighet">{currentItem.Fastighet}</Descriptions.Item>}
        {currentItem?.Latitud && <Descriptions.Item label="Plats">{currentItem.Latitud},{currentItem.Longitud}</Descriptions.Item>}
        {currentItem?.Latitud && <Descriptions.Item label="Google maps"><a target="_blank" href={`https://www.google.com/maps?q=${currentItem.Latitud},${currentItem.Longitud}`}>Google maps</a></Descriptions.Item>}
        {currentItem?.Created && (
          <Descriptions.Item label="Skapad">
            {currentItem.Created.replace('T', ' ').replace('Z', '')}{' '}
          </Descriptions.Item>
        )}
        {currentItem?.Namn && <Descriptions.Item label="Namn">{currentItem.Namn}</Descriptions.Item>}
        {currentItem?.Status && <Descriptions.Item label="Status">{currentItem.Status}</Descriptions.Item>}
        {currentItem?.Beskrivning && (
          <Descriptions.Item label="Beskrivning">{currentItem.Beskrivning}</Descriptions.Item>
        )}
        {currentItem?.Avslutadkommentar && <Descriptions.Item label="Kommentar vid avslut">{currentItem.Avslutadkommentar}</Descriptions.Item>}
        {currentItem?.Avslutadanledning && <Descriptions.Item label="Anledning till att ärendet avslutats">{currentItem.Avslutadanledning}</Descriptions.Item>}
      </WorkDescriptions>
      {currentItem && <ImageGallery item={currentItem} />}
    </>
  );
};

const ImageGallery = ({ item }: { item: SharePointItem }) => {
  const [currentImages, setCurrentImages] = useState<AttachmentFile[]>([]);
  const { user } = useUserContext();
  useEffect(() => {
    user.GraphToken &&
      getAttachments(item, user.GraphToken).then((newItem: any) => {
        setCurrentImages(newItem);
      });
  }, [item.ID]);
  return (
    <div className="image-gallery">
      {currentImages.map((image: AttachmentFile, index: number) => {
        return (
          <img
            key={index}
            src={image.content}
            alt={`Image ${index + 1}`}
            className="gallery-image"
            style={{ height: '250px', width: 'auto' }}
          />
        );
      })}
    </div>
  );
};

export default DisplayJob;