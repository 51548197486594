import React, { useEffect, useRef } from 'react';
import { getUsers, Inventering, SharePointItem } from '../Functions/ApiRequests';
import { filterDefaults, FilterFunction, FilterInventeringsFunction, useFilters } from '../contexts/FilterContext';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants/QueryKeys';

type FilterComponentProps = {
  allItems: SharePointItem[];
  inventeringar: Inventering[]
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItemType: 'ÄTA' | 'Inventeringar'
};

const FilterComponent = ({allItems, setShowFilters, inventeringar, selectedItemType }:FilterComponentProps) => {
  const {filters,setFilters, inventeringsFilter, setInventeringsFilter} = useFilters();
  const selectRef = useRef < HTMLSelectElement>(null);

  const AddFilter = (
    e:
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>,
    fn?: FilterFunction,
    key?: string
  ) => {
    if (!key) key = e.target.name;
    if (!fn) {
      fn = (item: SharePointItem) => item[e.target.name as keyof SharePointItem] === e.target.value;
    }
    if (e.target.value) {
      setFilters({
        ...filters,
        counter: filters.counter + 1,
        [key]: fn,
      });
    }
    if (!e.target.value || e.target.value === '') {
      const newFilters = { ...filters };
      delete newFilters[key];
      newFilters.counter = filters.counter + 1;
      setFilters(newFilters);
    }
  };

  const AddInventeringsFilter = (
    e:
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>,
    fn?: FilterInventeringsFunction,
    key?: string
  ) => {
    if (!key) key = e.target.name;
    if (!fn) {
      fn = (item: Inventering) => item[e.target.name as keyof Inventering] === e.target.value;
    }
    if (e.target.value) {
      setInventeringsFilter({
        ...inventeringsFilter,
        counter: inventeringsFilter.counter + 1,
        [key]: fn,
      });
    }
    if (!e.target.value || e.target.value === '') {
      const newFilters = { ...filters };
      delete newFilters[key];
      newFilters.counter = filters.counter + 1;
      setFilters(newFilters);
    }
  };
  const {data: users } = useQuery({queryKey: [QueryKeys.Users], queryFn: getUsers})


  return (
    <div className="absolute inset-0 m-auto flex items-center justify-center z-50">
      <div className="rounded-xl border border-gray-200 bg-white p-6 shadow-lg">
        <h2 className="text-stone-700 text-xl font-bold">Filter och Sortering</h2>
        {selectedItemType === 'ÄTA'  ? <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div>
            <div className="flex flex-col">
              <label htmlFor="datefrom" className="text-stone-600 text-sm font-medium">
                StartDatum
              </label>
              <input
                onChange={(e) =>
                  AddFilter(
                    e,
                    (item: SharePointItem) => {
                      return (
                        new Date(item.Created).toLocaleDateString() >= new Date(e.target.value).toLocaleDateString()
                      );
                    },
                    'Startdatum'
                  )
                }
                type="date"
                id="datefrom"
                name="Created"
                className="mt-2 mb-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="Avvikelsetyp-select" className="text-stone-600 text-sm font-medium">
                Avvikelsetyp
              </label>
              <select
                id="Avvikelsetyp-select"
                name="Avvikelsetyp"
                multiple
                ref={selectRef}
                onChange={(e) => {
                  AddFilter(e, (item: SharePointItem) => {
                    return Array.from(e.target.selectedOptions)
                      .map((o) => o.value)
                      .includes(item.Avvikelsetyp);
                  });
                }}
                className="mt-2 block h-40 w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                {[...new Set(allItems.map((item) => item.Avvikelsetyp))].map((Avvikelsetyp) => (
                  <option selected={Avvikelsetyp !== "Inventering"} key={Avvikelsetyp}>{Avvikelsetyp}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="dateto" className="text-stone-600 text-sm font-medium">
              SlutDatum
            </label>
            <input
              onChange={(e) =>
                AddFilter(
                  e,
                  (item: SharePointItem) => {
                    return new Date(item.Created).toLocaleDateString() <= new Date(e.target.value).toLocaleDateString();
                  },
                  'Slutdatum'
                )
              }
              type="date"
              id="dateto"
              name="Created"
              className="mt-2 mb-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <label htmlFor="Status-select" className="text-stone-600 text-sm font-medium">
              Status
            </label>
            <select
              id="Status-select"
              name="Status"
              onChange={(e) => {
                AddFilter(e);
              }}
              multiple
              className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
              {[...new Set(allItems.map((item) => item.Status))].map((Status) => (
                <option selected={Status !== 'Avslutad'  && Status !== "On Hold" ? true : false} key={Status}>
                  {Status}
                </option>
              ))}
            </select>
            <label htmlFor="Status-select" className="text-stone-600 text-sm font-medium">
              Kostnadsställe
            </label>
            <select
              id="kostnadsställe-select"
              name="Kostnadsst_x00e4_lle"
              onChange={(e) => {
                AddFilter(e);
              }}
              multiple
              className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
              {[...new Set(allItems.map((item) => item.Kostnadsst_x00e4_lle))].map((kst) => (
                <option key={kst}>
                  {kst}
                </option>
              ))}
            </select>
          </div>
          

          <div className="flex flex-col">
            {users?.value && (
              <>
                <label htmlFor="Tilldeladtill-select" className="text-stone-600 text-sm font-medium">
                  Tilldelad till
                </label>
                <select
                  id="Tilldeladtill-select"
                  name="Tilldeladtill"
                  onChange={(e) => {
                    AddFilter(e, (item) => item.Tilldeladtill === e.target.value);
                  }}
                  className="mt-2 mb-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                  <option></option>
                  {[...new Set(users.value.map((item: any) => item))]
                    .filter(
                      (e: any) =>
                        !['Mats Appelquist', 'Andreas Löfkvist', 'Marty Carryson'].includes(e.displayName as string)
                    )
                    .map((e: any) => (
                      <option value={e.userPrincipalName} key={e.displayName as string}>
                        {e.displayName as string}
                      </option>
                    ))}
                </select>
              </>
            )}
            <label htmlFor="Namn" className="text-stone-600 text-sm font-medium">
              Inrapporterad av
            </label>
            <select
              id="Namn"
              name="Namn"
              onChange={(e) => {
                AddFilter(e);
              }}
              className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
              <option></option>
              {[...new Set(allItems.map((item) => item.Namn))].map((namn) => (
                <option key={namn}>{namn}</option>
              ))}
            </select>
            { ["Henrik.Magnusson@greenlandscaping.se", "nicklas.ferhm@greenlandscaping.se","Mutaz.Nafel@greenlandscaping.se","andreas.lofkvist@GLNET.SE", "Lars.Bornesvard@greenlandscaping.se"].includes(sessionStorage.getItem("USERPRINCIPALNAME")!) && 
            <label htmlFor="Nyförsäljning" className="text-stone-600 text-sm font-medium">
              Visa Nyförsäljning
            </label>
}
            <input
            type="checkbox"
              id="Nyförsäljning"
              name="Nyförsäljning"
              onChange={(e) => {
                AddFilter(e,(item) => e.target.checked ? item.Nyf_x00f6_rs_x00e4_ljning : true);
              }}
              className="mt-2 block w-full rounded-md border px-2 py-2  outline-none ">
            </input>
          </div>
        </div> :
        
        <div>
          <div className="flex flex-col">
            <label htmlFor="Status-select" className="text-stone-600 text-sm font-medium">
              Kategori
            </label>
            <select
              id="Status-select"
              name="Status"
              onChange={(e) => {
                AddInventeringsFilter(e, (item) => item.Kategori.includes(e.target.value));
              }}
              multiple
              className="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                <option selected></option>
              {[...new Set(inventeringar.map((item) => item.Kategori))].map((Kategori) => (
                <option id={Kategori} value={Kategori} key={Kategori}>
                  {Kategori}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        }

        <div className="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
          <button
            onClick={() => setShowFilters(false)}
            className="active:scale-95 rounded-lg bg-blue-600 px-8 py-2 font-medium text-white outline-none focus:ring hover:opacity-90">
            Stäng
          </button>
          <button
            onClick={() => {setFilters(filterDefaults.filters);setInventeringsFilter(filterDefaults.inventeringsFilter);setShowFilters(false)}}
            className="active:scale-95 rounded-lg bg-blue-600 px-8 py-2 font-medium text-white outline-none focus:ring hover:opacity-90">
            Rensa filter
          </button>
        </div>
      </div>
    </div>
  );
};



export default FilterComponent;
