import React, { useContext, useEffect, useRef, useState } from 'react';
import { WorkDescriptions } from './DisplayJob.styles';
import { Button, Descriptions, Input, Select, message } from 'antd'; // Import Input from antd
import { Inventering, SharePointItem, createAnbudsDokument,  getFields,  getSharepointItems, getUsers, patchSharepointItems, uploadAnbudsDokument } from '../Functions/ApiRequests';
import { UserContext } from '../contexts/UserContext';
import { UserType, userCompanyEnum } from '../MsalAuthentication/UserConfig';
import axios from 'axios';
import { downloadBase64File } from '../Functions/downloadBase64File';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants/QueryKeys';
import useMessage from 'antd/es/message/useMessage';

interface EditJobProps {
  currentItem: SharePointItem | Inventering | null;
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | Inventering | null>>;
  setAllItems: React.Dispatch<React.SetStateAction<SharePointItem[]>>;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

const EditForm: React.FC<EditJobProps> = ({ currentItem, setCurrentItem, setAllItems, setCurrentTab }:EditJobProps) => {
  const [editedItem, setEditedItem] = useState<any>(currentItem); // State for edited item
  const { user } = useContext(UserContext);
  const [anbudVisible, setAnbudvisible] = useState<boolean>(false);
  const formRef = useRef(null);
  const [avslutas, setAvslutas] = useState<boolean>(false);
  const [antMessage] = useMessage();
  const {data:users } = useQuery({queryKey: [QueryKeys.Users],
    queryFn: getUsers
  })
  const handleInputChange = (key: string, value: string) => {
    if (key === 'Material') {
      const material = value ? value.split(',') : [];
      setEditedItem((prevState: any) => ({
        ...prevState,
        [key]: material,
      }));
    } else {
      setEditedItem((prevState: any) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };
  useEffect(() => {
    setEditedItem(currentItem);
  }, [currentItem]);

  const {data: fields} = useQuery({queryKey: [QueryKeys.Fields], queryFn: () => getFields(user.GraphToken!)})


  const handleSubmit = (e:any) => {
    e.preventDefault();
    if ((formRef.current! as any).checkValidity()) {
      const formData = new FormData(formRef.current!);
      const formEntries = Object.fromEntries(formData.entries());
      
      createAnbudsDokument({ ...editedItem, ...formEntries, userEmail: user.UPN }, user.GraphToken!).then(rs => {if (rs.status === 200) antMessage.success("Anbud skapas.. Det inkommer inom kort på din mail-adress.") }).catch(rs => antMessage.error("Något gick fel vid skapandet av anbudsdokument.")) ;

      setAnbudvisible(false);
    } else {
      (formRef.current as any)!.reportValidity(); // To show validation messages
    }
  };


  return (
    <div>
      <WorkDescriptions column={1} bordered style={{ overflowY: 'scroll', height: '77vh' }}>
        <Descriptions.Item label="Rubrik">
          <Input value={editedItem.Title} onChange={(e) => handleInputChange('Title', e.target.value)} />
        </Descriptions.Item>
        {editedItem.Adress && (
          <Descriptions.Item label="Adress">
            <Input value={editedItem.Adress} onChange={(e) => handleInputChange('Adress', e.target.value)} />
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Avvikelsetyp">
          <Select
            style={{ width: '200px' }}
            value={editedItem.Avvikelsetyp}
            onChange={(e) => handleInputChange('Avvikelsetyp', e)}>
            {['Akut', 'Vandalisering', 'Förbättringsförslag', 'Övrigt', 'Inventering', 'Markrevision'].map(
              (item, index) => {
                return <Select.Option key={item}>{item}</Select.Option>;
              }
            )}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Kategori">
          <Select
            style={{ width: '200px' }}
            value={editedItem.OData__x00c4_tatyp}
            onChange={(e) => handleInputChange('OData__x00c4_tatyp', e)}>
            {['Ej bestämd', 'Skötsel ingår', 'Skötsel ÄTA', 'Anläggning ingår', 'Anläggning ÄTA'].map((item, index) => {
              return <Select.Option key={item}>{item}</Select.Option>;
            })}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Kostnadsställe">
          <Select
            style={{ width: '200px' }}
            value={editedItem.Kostnadsst_x00e4_lle}
            onChange={(e) => handleInputChange('Kostnadsst_x00e4_lle', e)}>
            {fields?.find(f => f.EntityPropertyName === "Kostnadsst_x00e4_lle")?.Choices.results.map((item:string) => {
              return <Select.Option key={item}>{item}</Select.Option>;
            })}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Select style={{ width: '200px' }} value={editedItem.Status} onChange={(e) => handleInputChange('Status', e)}>
            {['Skapad', 'Kontakta kund', "Skickat till kund", 'Insåld', 'Avslutad', 'On Hold'].map((item, index) => {
              return <Select.Option key={item}>{item}</Select.Option>;
            })}
          </Select>
        </Descriptions.Item>
        {sessionStorage.getItem('USERCOMPANY') === userCompanyEnum.Wiridis && users !== null && users?.value && (
          <Descriptions.Item label="Tilldelad till">
            <Select
              style={{ width: '200px' }}
              value={
                users.value.find(
                  (u: any) =>
                    String(u.userPrincipalName).toLowerCase() === String(editedItem.Tilldeladtill).toLowerCase()
                )?.displayName
              }
              onChange={(e) => handleInputChange('Tilldeladtill', e)}>
              {users?.value &&
                users.value.filter((n:{displayName:string}) => !(process.env.NODE_ENV === "development" ? [] : ["Mats Appelquist", "Andreas Löfkvist", "Marty Carryson"]).includes(n.displayName)).map((item: any, index: number) => {
                  return (
                    <Select.Option key={'Item- ' + index} value={item.userPrincipalName}>
                      {item.displayName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Fastighet">
          <Input value={editedItem.Fastighet} onChange={(e) => handleInputChange('Fastighet', e.target.value)} />
        </Descriptions.Item>
        <Descriptions.Item label="Skapad">{editedItem.Created.replace('T', ' ').replace('Z', '')}</Descriptions.Item>
        <Descriptions.Item label="Namn">
          <Input value={editedItem.Namn} onChange={(e) => handleInputChange('Namn', e.target.value)} />
        </Descriptions.Item>

        <Descriptions.Item label="Beskrivning">
          <Input.TextArea
            value={editedItem.Beskrivning}
            onChange={(e) => handleInputChange('Beskrivning', e.target.value)}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Material">
          <Input
            value={editedItem.Material?.results?.join(',')}
            onChange={(e) => handleInputChange('Material', e.target.value)}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Uppskattad tidsåtgång">
          <Input
            value={editedItem.Uppskattad_x0020_tids_x00e5_tg_x}
            onChange={(e) => handleInputChange('Uppskattad_x0020_tids_x00e5_tg_x', e.target.value)}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Information">
          <Input.TextArea
            value={editedItem.Information}
            onChange={(e) => handleInputChange('Information', e.target.value)}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Pris">
          <Input
            value={editedItem.Godk_x00e4_ntpris}
            onChange={(e) => handleInputChange('Godk_x00e4_ntpris', e.target.value)}
          />
        </Descriptions.Item>

        {editedItem.Anbudskalkyll ? (
          <Descriptions.Item label="Anbudskalkyl">
            <a href={`${editedItem.Anbudskalkyll}`} target="_blank" rel="noreferrer">
              Länk till anbudskalkyl
            </a>
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label="Anbudskalkyl">
            <Button
              style={{ marginTop: '10px' }}
              onClick={() => {
                uploadAnbudsDokument(editedItem, user.GraphToken!, setAllItems, setCurrentTab);
              }}>
              Skapa anbudskalkyl
            </Button>
          </Descriptions.Item>
        )}
        {editedItem.Anbudskalkyll && (
          <Descriptions.Item label="AnbudsDokument">
            <Button
              style={{ marginTop: '10px' }}
              onClick={() => {
                setAnbudvisible((prev) => !prev);
              }}>
              Skapa Anbudsdokument
            </Button>
          </Descriptions.Item>
        )}

        <Descriptions.Item label="Spara">
          <Button
            style={{ marginTop: '10px' }}
            onClick={() => {
              if (editedItem.Status !== "Avslutad"){
              handlePatchItems(editedItem, setAllItems, setCurrentTab, user, setCurrentItem);
              }
              else {
                setAvslutas(true);
              }
            }}>
            Spara
          </Button>
        </Descriptions.Item>
      </WorkDescriptions>
      {anbudVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50" onClick={(e) => e.stopPropagation()}>
          <div className="bg-white p-5 rounded shadow-lg w-11/12 max-w-2xl relative">
            <Button className="absolute top-2 right-2" onClick={() => setAnbudvisible(false)}>
              X
            </Button>
            <form
              className="flex-col flex"
              name="AnbudsForm"
              ref={formRef}
              onInvalid={(e) => (e.target as any).setCustomValidity('Detta fält krävs!')}
              onInput={(e) => (e.target as any).setCustomValidity('')}>
              <h3>Kundinformation</h3>
              <div>
                <label htmlFor="CustomerName" className="mr-5">
                  Kund:
                </label>
                <Input name="CustomerName" required className="w-4/12 mb-2" placeholder="Namn Kund" />
              </div>
              <div>
                <label htmlFor="CustomerAddress" className="mr-5">
                  Adress:
                </label>
                <Input name="CustomerAddress" required className="w-4/12 mr-1 mb-2" placeholder="Adress" />
              </div>
              <div>
                <label htmlFor="CustomerZipCode" className="mr-5">
                  Postnr:
                </label>
                <Input name="CustomerZipCode" required className="w-2/12 mr-1 mb-2" placeholder="Postnr" />
              </div>
              <div>
                <label htmlFor="CustomerCity" className="mr-5">
                  Ort:
                </label>
                <Input name="CustomerCity" required className="w-4/12 mb-2" placeholder="Stad" />
              </div>
              <div>
                <label htmlFor="CustomerContact" className="mr-5">
                  Kontakt:
                </label>
                <Input name="CustomerContact" required className="w-4/12 mb-2" placeholder="Kontakt" />
              </div>
              <h3>Information om dig som skapar anbud</h3>
              <div>
                <label htmlFor="IssuedCity" className="mr-5">
                  Ort där Anbudet skapats:
                </label>
                <Input name="IssuedCity" required className="w-4/12 mb-2" placeholder="Utfärdat ort" />
              </div>
              <div>
                <label htmlFor="EmailContact" className="mr-5">
                  Email:
                </label>
                <Input name="EmailContact" required className="w-4/12 mb-2" placeholder="email" />
              </div>
              <div>
                <label htmlFor="PhnContact" className="mr-5">
                  Telefonnummer:
                </label>
                <Input name="PhnContact" required className="w-4/12 mb-2" placeholder="telefonnummer" />
              </div>
              <div>
                <label htmlFor="FullnameContact" className="mr-5">
                  Namn på person som skapar anbud:
                </label>
                <Input
                  name="FullnameContact"
                  required
                  className="w-4/12 mb-2"
                  placeholder="namn"
                  defaultValue={user.name}
                />
              </div>
              <h3>Övrig information</h3>
              <div>
                <label htmlFor="CustomerZipCode" className="mr-5">
                  Första kontakt med kund gällande ärendet:
                </label>
                <Input name="AskDate" required className="w-3/12 mb-2" type="date" />
              </div>
              <div>
                <label htmlFor="ValidFor" className="mr-5">
                  Anbudet är giltigt i så många dagar:
                </label>
                <Input name="ValidFor" required className="w-3/12 mb-2" type="number" defaultValue={30} />
              </div>
              <div>
                <label htmlFor="Digitalsignatur" className="mr-5">
                  Jag intygar att informationen ovan är korrekt och att jag är användaren av detta konto:
                </label>
                <Input name="Digitalsignatur" required className="w-3/12 mb-2" type="checkbox" />
              </div>

              <Button
                style={{ marginTop: '10px' }}
                form={'AnbudsForm'}
                formAction="submit"
                onClick={(e) => {
                  e.preventDefault();
                
                  handleSubmit(e);
                }}>
                Skapa Anbudsdokument
              </Button>
            </form>
          </div>
        </div>
      )}
{avslutas && 
<div className="fixed inset-0 flex items-center justify-center z-50" onClick={(e) => e.stopPropagation()}>
<div className="bg-white p-5 rounded shadow-lg w-11/12 max-w-2xl relative">
<Button className="absolute top-2 right-2" onClick={() => setAvslutas(false)}>
              X
            </Button>
        <h2>Avsluta ÄTA</h2>
        <div className="grid grid-cols-2 w-8/12">
        <label htmlFor="Avslutadanledning" className="">Anledning: </label>
        <select title="AvslutadAnledning" name="Avslutadanledning" onChange= {(e) => setEditedItem((prev:SharePointItem) => ({...prev, "Avslutadanledning":e.target.value}))} className="p-1 ml-2 rounded bg-white border-2 w-60">
          <option></option>
          {fields?.find(field => field.EntityPropertyName==="Avslutadanledning")?.Choices?.results.map((r:any) => 
            <option value={r}>{r}</option>
          )}
        </select>
        <label htmlFor="Avslutadkommentar" className="mt-2">Kommentar: </label>
        <textarea title="Avslutadkommentar" name="Avslutadkommentar" onChange= {(e) => setEditedItem((prev:SharePointItem) => ({...prev, "Avslutadkommentar":e.target.value}))} className="ml-2 mt-2 rounded border-2 h-16 w-60" ></textarea>
      <span></span>
      <Button
      id="AvslutaÄrende"
            className="mt-2 ml-2"
            disabled={!(editedItem.Avslutadanledning && editedItem.Avslutadkommentar)}
            onClick={() => {
              handlePatchItems(editedItem, setAllItems, setCurrentTab, user, setCurrentItem).then(() => setAvslutas(false));
            }}>
            Avsluta ärendet
          </Button>
      </div>
      </div>
      </div>}
    </div>
  );
};

const handlePatchItems = async  (
  editedItem: any,
  setAllItems: React.Dispatch<React.SetStateAction<SharePointItem[]>>,
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>,
  user: UserType,
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | Inventering | null>>
) => {
  if (editedItem.Tilldeladtill){
    try {
    console.log("TilldeladTill är aktiv")
    editedItem.Nyf_x00f6_rs_x00e4_ljning = false;
    await axios.post(
      'https://prod-77.westeurope.logic.azure.com:443/workflows/36d15981634f4f2baded687e6e0ff131/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QbievDyI0iHQxJ5xmB2Rl9qfg07lSxO5ltocIBnUedc',
      { Tilldeladtill: editedItem.Tilldeladtill, ID: editedItem.ID }
    );}
    catch {console.error("Kunde inte sätta tilldelad till")}
  }
  await patchSharepointItems(editedItem, user.GraphToken!);
  await getSharepointItems(null, user.GraphToken!).then((items) => {
  setAllItems(items);
  message.success('Lyckades spara posten.');
  setCurrentItem(item => items.find(i => i.ID === item!.ID)!)
  setCurrentTab('DisplayJob');
      }); 
};
export default EditForm;