import React, { useContext, useEffect, useState } from 'react';
import DisplayJob from './Pages/DisplayJob';
import ItemsGallery from './components/ItemsGallery';
import TopSegment from './components/TopSegment';
import './WebApp.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from './contexts/UserContext';
import { Inventering, SharePointAttachment, SharePointItem, getSharepointItems } from './Functions/ApiRequests';
import AdditionalWorkMap from './components/Map/AdditionalWorkMap';
import EditForm from './Pages/EditForm';
import { AvvikelsetypColors } from './components/Colors';
import { FilterFunction, FilterInventeringsFunction, useFilters } from './contexts/FilterContext';
import StatisticsPage from './Pages/StatisticsPage';


function WebApp() {
  const [currentItem, setCurrentItem] = useState<SharePointItem | Inventering | null>(null);
  const [allItems, setAllItems] = useState<SharePointItem[]>([]);
  const [displayedItems, setDisplayedItems] = useState<SharePointItem[]>([]);
  const [displayedInventeringar, setDisplayedInventeringar] = useState<Inventering[]>([]);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [inventeringar, setInventeringar] = useState<Inventering[]>([]);
  const [selectedItemType, setSelectedItemType] = useState<'ÄTA' | 'Inventeringar'>('ÄTA');
  const [currentTab, setCurrentTab] = useState<string>('DisplayJob');
  const { user } = useContext(UserContext);
  const [attachments] = useState<SharePointAttachment[]>([]);
  const { filters, inventeringsFilter } = useFilters();

  useEffect(() => {
    if (user.GraphToken) {
      getSharepointItems(null, user.GraphToken).then((items) => {
        setAllItems(items);
      });
    }
  }, []);

  useEffect(() => {
    setDisplayedItems(
      () => allItems.filter(item => 
         Object.keys(filters).every(i => {
          if (i==="counter")
            return true
          else {
          return (filters as {[key:string]:FilterFunction})[i](item)
        }
  }
      )));
      
  },[filters?.counter])
  useEffect(() => {
setDisplayedInventeringar(() =>
  inventeringar
    .filter((item) => {
      return Object.keys(inventeringsFilter).every((i) => {
        if (i === 'counter') return true;
        else {
          return (inventeringsFilter as { [key: string]: FilterInventeringsFunction })[i](item);
        }
      });
    })
    .sort((i) => 0 - i.ID)
);
  },[inventeringsFilter?.counter])

  useEffect(() => {
    setDisplayedItems(() =>
      allItems.filter((item) =>
        Object.keys(filters).every((i) => {
          if (i === 'counter') return true;
          else {
            return (filters as { [key: string]: FilterFunction })[i](item);
          }
        })
      ).sort(i => 0-i.ID)
    );
  }, [allItems]);
  useEffect(() => {
    if (window.location.pathname.startsWith("/setID/") && allItems){
      setCurrentItem(prev => {
        const item = allItems.find(d => d.ID ===Number(window.location.pathname.replace("/setID/","")) )
        if (item) {
          return item;
        }
        else {
          return prev;
        }
      })
    }
  },[allItems, window.location.pathname])
  
  useEffect(() => {
    setDisplayedInventeringar(inventeringar)
  }, [inventeringar.length])

  useEffect(() => {
    currentTab === 'Map' && mapInstance && mapInstance.resize();
  }, [currentTab]);

  return (
    <div className="webapp-grid-container">
      <div className="webapp-grid-top-segment">
        <TopSegment />
      </div>
      <div className="webapp-grid-content">
        <div className="webapp-grid-items-gallery">
          <ItemsGallery
            mapInstance={mapInstance}
            setCurrentItem={setCurrentItem}
            currentItem={currentItem}
            allItems={allItems}
            setAllItems={setAllItems}
            displayedItems={displayedItems}
            setDisplayedItems={setDisplayedItems}
            attachments={attachments}
            inventeringar={inventeringar}
            displayedInventeringar={displayedInventeringar}
            setInventeringar={setInventeringar}
            selectedItemType={selectedItemType}
            setSelectedItemType={setSelectedItemType}
          />
        </div>
        <div className="webapp-grid-page">
          <Tabs onSelect={(e) => e && setCurrentTab(e)} activeKey={currentTab}>
            <Tab eventKey="DisplayJob" title="Visa Jobb">
              <DisplayJob currentItem={currentItem} setCurrentItem={setCurrentItem} />
            </Tab>
            {/* <Tab eventKey="ChangeStatus" title="Ändra status">
              {currentItem && (
                <ChangeStatus
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                  setAllItems={setAllItems}
                  setCurrentTab={setCurrentTab}
                />
              )}
            </Tab> */}
            {selectedItemType === "ÄTA" && 
            <Tab eventKey="EditJob" title="Hantera hela jobbet">
              {currentItem && (
                <EditForm
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                  setAllItems={setAllItems}
                  setCurrentTab={setCurrentTab}
                />
              )}
            </Tab>

            }

            <Tab eventKey="Map" title="Karta">
              <div style={{ width: '70vw', height: '76vh' }}>
                <div style={{ height: '50px', width: '100%', display: 'flex', flexDirection: 'row' }}>
                  {Object.keys(AvvikelsetypColors).map((key) => (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      {key}:
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: AvvikelsetypColors[key],
                          borderRadius: '50%',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      />
                    </div>
                  ))}
                </div>
                <AdditionalWorkMap
                  mapInstance={mapInstance}
                  setMapInstance={setMapInstance}
                  setCurrentItem={setCurrentItem}
                  currentItem={currentItem}
                  displayedItems={displayedItems}
                  setDisplayedItems={setDisplayedItems}
                  inventeringar={displayedInventeringar}
                  selectedItemType={selectedItemType}
                />
              </div>
            </Tab>{
              selectedItemType !== "Inventeringar" &&
            <Tab eventKey="Statistik" title="Statistik">
              {
                <div>
                  <h2>Fördelning status på arbeten</h2>
                  <StatisticsPage data={displayedItems} allItems={allItems}/>
                </div>
              }
            </Tab>}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default WebApp;